import { Context } from '@BlackbirdHQ/ui-base';
import { COOKIE_SESSION_NAME, SESSION_STORAGE_TOKEN } from '@BlackbirdHQ/ui-base/constants';
import { useSessionStorage } from '@BlackbirdHQ/ui-base/hooks';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpOptions,
  InMemoryCache,
  ServerError,
  ServerParseError,
  createHttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import DebounceLink from 'apollo-link-debounce';
import { inflate } from 'graphql-deduplicator';
import { useRouter } from 'next/router';
import { FC, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { v4 } from 'uuid';

import omitDeep from '@/helpers/omit-deep';
import { useURLQuery } from '@/hooks';

function isNetworkError(error: Error): error is ServerError | ServerParseError {
  return typeof (error as ServerError | ServerParseError).statusCode === 'number';
}

interface HeaderOptions {
  token?: string;
  language?: string;
}

export interface Stop {
  start: string | number | null;
  end: string | number | undefined | null;
}

const memCache = (initialState: Record<string, any>) =>
  new InMemoryCache({
    possibleTypes: {
      Peripheral: ['Sensor', 'Camera'],
    },
    addTypename: true,
    resultCaching: true,
    typePolicies: {
      // Disable normalization for individual sample points.
      Sample: {
        keyFields: false,
      },
      // Specify the cache keys on fields that don't have an _id or id field.
      Peripheral: {
        keyFields: ['_id'],
      },
      Batch: {
        keyFields: ['batchId'],
      },
      StopCauseMapping: {
        keyFields: ['peripheralIdTarget', 'peripheralIdEvent'],
      },
      BatchControl: {
        keyFields: ['batchControlId'],
      },
      Extension: {
        keyFields: ['type', 'service'],
      },
      ControlReceipt: {
        keyFields: ['controlReceiptId'],
      },
      WorkOrderKey: {
        keyFields: ['key', 'tagId'],
      },
      Permission: {
        keyFields: ['key', 'type'],
      },
      Andon: {
        keyFields: ['companyId'],
        fields: {
          calls: {
            keyArgs: ['lineId'],
            read(existing = []) {
              return existing;
            },
            merge(existing = [], incoming, { args }) {
              const isLatestIncoming = !args?.time;
              return [...incoming, ...existing].filter((call, i, calls) => {
                const isExistingIndex = incoming.length <= i;
                const isCancelled = isLatestIncoming && isExistingIndex;
                return calls.findIndex((c) => c.__ref === call.__ref) === i && !isCancelled;
              });
            },
          },
        },
      },
      Product: {
        keyFields: false,
      },
      AndonShift: {
        merge: true,
        keyFields: ['id'],
        fields: {
          attendees: {
            merge: false,
          },
        },
      },
      // Some items don't have a key element at all, so we specify how to handle merging them in the cache.
      Device: {
        fields: {
          sensor: {
            merge: true,
          },
        },
      },
      SensorTimeData: {
        fields: {
          samples: {
            merge: false,
          },
        },
      },
      LineTimeData: {
        fields: {
          samples: {
            merge: false,
          },
        },
      },
      Sensor: {
        fields: {
          config: {
            merge: true,
          },
          time: {
            merge: false,
          },
          peripheralInformation: {
            merge: true,
          },
        },
      },
      Line: {
        fields: {
          time: {
            merge: false,
          },
        },
      },
      Group: {
        fields: {
          users: {
            // Alternatively, this could be merged same as https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays-of-non-normalized-objects.
            merge: false,
          },
          peripherals: {
            merge: false,
          },
          lines: {
            merge: false,
          },
        },
      },
    },
  }).restore(initialState || {});

/**
 * Handle responses using the graphql-deduplicate middleware.
 */
const inflateLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    return inflate(response);
  });
});

const cleanTypeNameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = omitDeep(operation.variables, '__typename');
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const create = (
  { token, language }: HeaderOptions,
  snackbarSpawn: Function,
  initialState: Record<string, any> = {},
  enableDeduplication = false,
  clearToken: Function,
) => {
  const headers: Record<string, string> = {
    'Accept-Language': language || 'en',
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  let apiUri = '/api';
  if (enableDeduplication) {
    apiUri = `${apiUri}?deduplicate=true`;
  }

  const httpLinkOptions: HttpOptions = {
    // Adding deduplicate=1 indicates to the server that we support graphql-deduplication.
    uri: apiUri,
    fetch,
    fetchOptions: { method: 'POST' },
    credentials: 'same-origin',
    headers,
  };

  const errorLink = onError(({ graphQLErrors = [], networkError }) => {
    if (graphQLErrors.length) {
      console.error(
        [
          '[ApolloClient]',
          ...graphQLErrors.map(({ message, locations, path }) => {
            return ` - ${message}, Location: ${locations}, Path: ${path}`;
          }),
        ].join('\n'),
      );

      for (const { message } of graphQLErrors) {
        snackbarSpawn(message, 'error');
      }
    }

    if (!networkError) {
      return;
    }

    if (!isNetworkError(networkError)) {
      return;
    }

    switch (networkError.statusCode) {
      case 404:
        console.warn('! 404 :thinking:');
        break;
      case 401:
      case 403:
        window.location.href = `/auth/login?url=${window.location.href}`;
        break;
      default:
        snackbarSpawn(networkError.message, 'error');
        console.error('[ApolloClient.errorLink] Unknown Apollo Network Error:', networkError.response);
        break;
    }
  });

  const debounceTimeout = 400;

  const links = [
    cleanTypeNameLink,
    new DebounceLink(debounceTimeout),
    errorLink,
    new RetryLink({
      delay: {
        initial: 2000, // 2 seconds before attempting first retry
        max: Infinity, // wait until the retry returns before starting subsequent retries
        jitter: true, // add randomized delays between retries to avoid thundering herd
      },
      attempts: {
        max: 2, // We have set this to 2 only for it to go into `retryIf`, where we always stop the retry.
        retryIf: async (error, _operation) => {
          if (!error) {
            return false;
          }

          if (!(error instanceof Error)) {
            return false;
          }

          if (!isNetworkError(error)) {
            return false;
          }

          const { name, statusCode } = error;

          if (typeof statusCode === 'number') {
            switch (statusCode) {
              case 401:
                if (headers.Authorization) {
                  // Not sure RetryLink will wait for clearToken to terminate, let's just smash the Authorization header
                  delete headers.Authorization;
                  clearToken();
                  return true;
                } else {
                  return false;
                }
              default:
            }
          }

          if ('result' in error) {
            // Fix for apollo sometimes returning array of errors and sometimes only 1 error
            let message = '';
            if ('errors' in error.result) {
              message = error.result.errors[0].message;
            }
            if ('message' in error.result) {
              message = error.result.message;
            }
            if (message.includes('Failed to fetch')) {
              console.warn('! Failed to connect to the API, trying again in 10 seconds');

              await new Promise((resolve) => setTimeout(resolve, (10).seconds));

              return true;
            }
          }

          if (name.includes('SyntaxError')) {
            console.warn('Got SyntaxError, stopping retry:', error);
            return false;
          }

          if (name.includes('Internal server error')) {
            console.warn('Got internal server error, stopping retry:', error);
            return false;
          }

          if (name.includes('ServerError')) {
            console.warn('Got server error, stopping retry:', error);
            return false;
          }

          // TODO: Report unhandled error cases to rollbar.
          console.error('[ApolloClient retryIf] Unhandled error:', error.message, error.stack);
          return true;
        },
      },
    }),
  ];

  if (enableDeduplication) {
    links.push(inflateLink);
  }

  // `createHttpLink` is a terminating link, so it needs to be last.
  links.push(createHttpLink(httpLinkOptions));
  return new ApolloClient({
    connectToDevTools: process.browser,
    ssrMode: !process.browser, // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from(links),
    cache: memCache(initialState),
    defaultOptions: {
      mutate: { errorPolicy: 'ignore' },
    },
    assumeImmutableResults: true,
    // defaultOptions: defaultOptions,
  });
};

const BlackbirdApolloProvider: FC = ({ children }) => {
  const { token: newToken, ...restQuery } = useURLQuery();
  const [token, setToken] = useSessionStorage<string | undefined>(SESSION_STORAGE_TOKEN, undefined);
  const { spawn } = useContext(Context.Snackbar);
  const [, { language }] = useTranslation();

  const router = useRouter();
  const uc = useMemo(() => new Cookies(), []);

  useEffect(() => {
    if (!token) {
      uc.remove(COOKIE_SESSION_NAME);
    }

    if (newToken && newToken !== token) {
      // FIXME: Consider doing this differently - the cookie is used as a cache
      // key and may work -without- the token (TTL /5 min). Maybe just use the
      // token as a value and verify it in the authorizer. That only makes sense
      // once we can generate tokens dynamically.
      uc.set(COOKIE_SESSION_NAME, v4(), {
        path: '/',
        secure: true,
        sameSite: 'strict',
      });

      setToken(newToken);

      if (typeof window !== 'undefined') {
        router.push({
          pathname: router.pathname,
          query: restQuery,
        });
      }
    }
  }, []);

  const apolloClient = useMemo(() => {
    return create(
      { token, language },
      spawn,
      {},
      true, // Enable deduplication in the Apollo client.
      () => setToken(undefined),
    );
  }, [spawn, token, setToken]);

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default BlackbirdApolloProvider;
