import { Breakpoint, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { useWidth } from '@/hooks';

export interface WithWidth {
  width: Breakpoint;
  fullScreen: boolean;
}

export const withWidth =
  () =>
  <T extends WithWidth>(WrappedComponent: React.ComponentType<T>): React.FunctionComponent =>
  (props) => {
    const theme = useTheme();
    const width = useWidth();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return <WrappedComponent {...(props as T)} width={width} fullScreen={fullScreen} />;
  };

const breakpoints: Record<Breakpoint, number> = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
  // @ts-ignore
  xxl: 1650,
};

/**
 * @deprecated better refactor the component into a functional one and use the theme breakpoints
 */
export const isWidthUp = (compareTo: Breakpoint, width: Breakpoint): boolean =>
  breakpoints[width] >= breakpoints[compareTo];
/**
 * @deprecated better refactor the component into a functional one and use the theme breakpoints
 */
export const isWidthDown = (compareTo: Breakpoint, width: Breakpoint): boolean =>
  breakpoints[width] <= breakpoints[compareTo];
