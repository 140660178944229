import { NavigationDrawer as BaseDrawer } from '@BlackbirdHQ/ui-base';
import { useQuery } from '@apollo/client';
import {
  Build,
  ExitToApp,
  Factory,
  Feedback,
  FileCopy,
  Group,
  GroupWork,
  MonitorHeart,
  PolylineOutlined,
  RingVolume,
  Sensors,
} from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';

import { COMPANIES } from '@/constants';
import { hierarchyNode } from '@/graphql/queries';
import { useIAM } from '@/hooks/use-iam';
import { useTranslation } from '@/hooks/use-translation';
import * as Feature from '@/lib/features';
import { Module } from '@/types/auth-types';

const useStyles = makeStyles()(() => ({
  flipIconHorizontal: {
    transform: 'scaleX(-1)',
  },
  print: {
    '@media print': {
      display: 'none',
    },
  },
}));

type NavigationDrawerProps = {
  open: boolean;
  toggleOpen: () => void;
  companyId?: string;
  features?: Feature.FeatureMap;
  headerLabel?: React.ReactNode;
  hideWhenClosed?: boolean;
};

const defaultFeatureMap = {
  group: {},
  line: {},
  peripheral: {},
  userPool: {},
};

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({
  open,
  toggleOpen,
  companyId,
  features = defaultFeatureMap,
  headerLabel,
  hideWhenClosed,
}) => {
  const { t } = useTranslation(['shared']);
  const { hasModuleAccess } = useIAM();

  const { classes } = useStyles();

  const cockpitAvailable = [COMPANIES.BLACKBIRD, COMPANIES.BLACKBIRD_STAGING, COMPANIES.DITSCH].some(
    (cockpitPool) => cockpitPool === companyId,
  );

  const scheduledReportsAvailable = Feature.inAnyGroup(features)(['Reports.ScheduledReport'], 'M');

  const maintenanceModeAvailable = hasModuleAccess(Module.Maintenance);
  const hierarchyModuleAvailable = hasModuleAccess(Module.Hierarchy);

  const { data } = useQuery(hierarchyNode, { errorPolicy: 'all' });

  const topNavigationLinks = [
    {
      href: '/',
      icon: <PolylineOutlined />,
      title: t(['shared:lines'], { defaultValue: 'Lines' }),
    },
    {
      href: '/sensors',
      icon: <Sensors />,
      title: t(['shared:sensors'], { defaultValue: 'Sensors' }),
    },
    {
      href: '/consolidated-lines',
      icon: <GroupWork />,
      title: t(['shared:consolidatedLines'], { defaultValue: 'Consolidated lines' }),
    },
    {
      href: '/andon',
      icon: <RingVolume />,
      title: t(['shared:andon'], { defaultValue: 'Andon' }),
    },
  ];

  if (hierarchyModuleAvailable && Boolean(data?.hierarchyNode)) {
    // Show organization as the top navigation link
    topNavigationLinks.unshift({
      href: '/organization',
      icon: <Factory />,
      title: t(['shared:organization'], { defaultValue: 'Organization' }),
    });
  }

  if (scheduledReportsAvailable) {
    topNavigationLinks.push({
      href: '/reports',
      icon: <FileCopy />,
      title: t(['shared:scheduledReports'], { defaultValue: 'Scheduled reports' }),
    });
  }

  if (cockpitAvailable) {
    topNavigationLinks.push({
      href: '/cockpit',
      icon: <MonitorHeart />,
      title: t(['shared:cockpit'], { defaultValue: 'Cockpit' }),
    });
  }

  if (maintenanceModeAvailable) {
    topNavigationLinks.push({
      href: '/maintenance',
      icon: <Build />,
      title: t(['maintenance:preventiveMaintenance'], { defaultValue: 'Preventive maintenance' }),
    });
  }

  const bottomNavigationLinks = [
    {
      href: '/feedback',
      icon: <Feedback />,
      title: t(['shared:feedback'], { defaultValue: 'Feedback' }),
    },
    {
      href: '/auth/logout',
      icon: <ExitToApp className={classes.flipIconHorizontal} />,
      title: t(['shared:logout'], { defaultValue: 'Logout' }),
    },
  ];

  if (Feature.inAnyUserPool(features)(['IAM.Role', 'IAM.Group', 'IAM.User'], 'M')) {
    bottomNavigationLinks.unshift({
      href: '/administration',
      icon: <Group />,
      title: t(['shared:administration'], { defaultValue: 'Administration' }),
    });
  }

  return (
    <BaseDrawer
      open={open}
      toggleOpen={toggleOpen}
      topNavigationLinks={topNavigationLinks}
      bottomNavigationLinks={bottomNavigationLinks}
      headerLabel={headerLabel}
      hideWhenClosed={hideWhenClosed}
    />
  );
};

export default NavigationDrawer;
