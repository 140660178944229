import { useLocalStorage } from '@BlackbirdHQ/ui-base';
import { ArrowForward } from '@mui/icons-material';
import { ViewQuilt } from '@mui/icons-material';
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import { xor } from 'lodash';
import { useRouter } from 'next/router';
import * as React from 'react';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import GroupsSidebarWrapper from '@/components/bars/groups-sidebar-wrapper';
import { useTranslation } from '@/hooks/use-translation';

import { ChartSpan, DashboardItemType, StopType } from './types';

const useStyles = makeStyles()((theme) => ({
  list: {
    minWidth: 460,
  },
  menuItemStyles: {
    padding: theme.spacing(0),
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  menuItemTitle: {
    color: theme.palette.text.primary,
  },
  opacity6: {
    opacity: '0.6 !important',
  },
}));

type DashboardMenuProperties = {
  lineId: string;
};

const DashboardMenu: React.FC<DashboardMenuProperties> = ({ lineId }) => {
  const [open, setOpen] = useState(false);
  const [showGroupSidebar, setShowGroupSidebar] = useState(false);

  const { classes } = useStyles();
  const { t } = useTranslation(['dashboard']);
  const router = useRouter();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [chartSpan, setChartSpan] = useLocalStorage<ChartSpan>(`${lineId}_CHART_SPAN`, ChartSpan.HOURS_24);
  const [showStops, setShowStops] = useLocalStorage<boolean>(`${lineId}_SHOW_STOPS`, false);
  const [redirectToRegStops, setRedirectToRegStops] = useLocalStorage<boolean>(
    `${lineId}_REDIRECT_TO_REG_STOPS`,
    false,
  );
  const [showLabels, setShowLabels] = useLocalStorage<boolean>(`${lineId}_SHOW_LABELS`, false);
  const [subtractStopTypes, setSubtractStopTypes] = useLocalStorage<StopType[]>(`${lineId}_SUBTRACT_STOP_TYPES`, []);
  const [showStopsOfTypes, setShowStopsOfTypes] = useLocalStorage<StopType[]>(`${lineId}_SHOW_STOPS_OF_TYPE`, []);
  const [includePlannedBatchTimes, setIncludePlannedBatchTimes] = useLocalStorage<boolean>(
    `${lineId}_INCLUDE_PLANNED_BATCH_TIMES`,
    false,
  );

  const toggleSubtractStopType = (type: StopType) => {
    setSubtractStopTypes((types: StopType[]) => xor(types, [type]));
  };

  const toggleShowStopsOfType = (type: StopType) => {
    setShowStopsOfTypes((types: StopType[]) => xor(types, [type]));
  };

  const dashboardItemType = router.pathname.includes('shift') ? DashboardItemType.SHIFT : DashboardItemType.BATCH;

  const onSelectGroup = (groupId: string) => {
    setShowGroupSidebar(false);
    const pathname =
      dashboardItemType === DashboardItemType.SHIFT ? '/dashboard/shift-group' : '/dashboard/batch-group';

    const query = { ...router.query, groupId };

    router.push({ pathname, query });
  };

  // Manually set root width to avoid expanding when opening GroupSidebar
  return (
    <div style={{ width: 50 }}>
      <Tooltip
        title={t(['shared:configureDashboardView'], { defaultValue: 'Configure dashboard view' }) || ''}
        placement="bottom-start"
      >
        <IconButton
          aria-label="More"
          aria-owns={false ? 'long-menu' : undefined}
          aria-haspopup="true"
          color="inherit"
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setOpen(true);
          }}
          size="large"
        >
          <ViewQuilt />
        </IconButton>
      </Tooltip>
      <Menu
        id="configuration-menu"
        open={open}
        keepMounted
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        classes={{ list: classes.list }}
      >
        <MenuItem dense disabled className={classes.opacity6}>
          <ListItemText
            classes={{ secondary: classes.menuItemTitle, primary: classes.menuItemTitle }}
            secondary={t(['shared:liveChartSpan'], { defaultValue: 'Live chart span' })}
          />
        </MenuItem>
        <MenuItem disableRipple className={classes.menuItemStyles}>
          <FormControl variant="filled" component="fieldset" className={classes.formControl}>
            <RadioGroup
              color="primary"
              aria-label="rollingtype"
              name="rollingType"
              value={chartSpan}
              onChange={(_, v) => setChartSpan(v as ChartSpan)}
            >
              <FormControlLabel
                value={ChartSpan.NONE}
                control={<Radio color="primary" />}
                label={t(['shared:none'], { defaultValue: 'none' })}
              />

              <FormControlLabel
                value={ChartSpan.HOURS_24}
                control={<Radio color="primary" />}
                label={t(['shared:rollingPast24Hours'], { defaultValue: 'Rolling past 24 hours' })}
              />
              <FormControlLabel
                value={ChartSpan.CURRENT}
                control={<Radio color="primary" />}
                label={
                  dashboardItemType === DashboardItemType.SHIFT
                    ? t(['shifts:currentShift'], { defaultValue: 'Current shift' })
                    : t(['batches:currentBatch'], { defaultValue: 'Current batch' })
                }
              />
            </RadioGroup>
          </FormControl>
        </MenuItem>
        <Divider />
        <MenuItem dense disabled className={classes.opacity6}>
          <ListItemText
            classes={{ secondary: classes.menuItemTitle }}
            secondary={t(['shared:liveChartContent'], { defaultValue: 'Live chart content' })}
          />
        </MenuItem>

        <MenuItem onClick={() => setShowStops((s) => !s)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox color="primary" name="showStops" checked={showStops} disableRipple />
          </ListItemIcon>
          <ListItemText primary={t(['shared:showStops'], { defaultValue: 'Show stops' })} />
        </MenuItem>
        <Divider />

        <MenuItem dense disabled className={classes.opacity6}>
          <ListItemText
            classes={{ secondary: classes.menuItemTitle }}
            secondary={t(['shared:preferences'], { defaultValue: 'Preferences' })}
          />
        </MenuItem>

        <MenuItem onClick={() => setRedirectToRegStops((s: boolean) => !s)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              name="toggleProductionMeterAndRegStops"
              checked={redirectToRegStops}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            primary={t(['shared:automaticallyRedirectToAndFromTheStopRegistrationPage'], {
              defaultValue: 'Automatically redirect to and from the stop registration page',
            })}
          />
        </MenuItem>

        <Divider />

        <MenuItem dense disabled className={classes.opacity6}>
          <ListItemText
            classes={{ secondary: classes.menuItemTitle }}
            secondary={t(['shared:showLabels'], { defaultValue: 'Show labels' })}
          />
        </MenuItem>
        <MenuItem onClick={() => setShowLabels((s) => !s)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox color="primary" name={'showLabelsOnProductionMeter'} checked={showLabels} disableRipple />
          </ListItemIcon>
          <ListItemText primary={t(['shared:ShowlabelsOnMeter'], { defaultValue: 'Show labels on meter' })} />
        </MenuItem>

        <Divider />
        <MenuItem dense disabled className={classes.opacity6}>
          <ListItemText
            classes={{ secondary: classes.menuItemTitle }}
            secondary={t(['shared:subtractStopTypesFromTarget'], {
              defaultValue: 'Subtract stop types from target',
            })}
          />
        </MenuItem>

        <MenuItem onClick={() => toggleSubtractStopType(StopType.NO_ACT)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              name="NO_ACT"
              checked={subtractStopTypes.includes(StopType.NO_ACT)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['stop:noActivityAtLine'], { defaultValue: 'No Activity At Line' })} />
        </MenuItem>

        <MenuItem onClick={() => toggleSubtractStopType(StopType.DOWNTIME)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              name="DOWNTIME"
              checked={subtractStopTypes.includes(StopType.DOWNTIME)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['stop:lossDuringOperation'], { defaultValue: 'Loss During Operation' })} />
        </MenuItem>

        <MenuItem onClick={() => toggleSubtractStopType(StopType.BATCH_NON_PROD)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              name="BATCH_NON_PROD"
              checked={subtractStopTypes.includes(StopType.BATCH_NON_PROD)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            primary={t(['stop:batchSpecificNonOperation'], { defaultValue: 'Batch Specific Non Operation' })}
          />
        </MenuItem>

        <MenuItem onClick={() => toggleSubtractStopType(StopType.NON_PROD)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              name="NON_PROD"
              checked={subtractStopTypes.includes(StopType.NON_PROD)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['stop:nonProductionActivities'], { defaultValue: 'Non Production Activities' })} />
        </MenuItem>

        <MenuItem onClick={() => toggleSubtractStopType(StopType.UNREGISTERED)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              name="UNREGISTERED_STOPS"
              checked={subtractStopTypes.includes(StopType.UNREGISTERED)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['stop:unregisteredStops'], { defaultValue: 'Unregistered Stops' })} />
        </MenuItem>

        <Divider />
        <MenuItem dense disabled className={classes.opacity6}>
          <ListItemText
            classes={{ secondary: classes.menuItemTitle }}
            secondary={t(['shared:showStopsOfType'], { defaultValue: 'Show stops of type' })}
          />
        </MenuItem>

        <MenuItem onClick={() => toggleShowStopsOfType(StopType.NO_ACT)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              name="NO_ACT"
              checked={showStopsOfTypes.includes(StopType.NO_ACT)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['stop:noActivityAtLine'], { defaultValue: 'No Activity At Line' })} />
        </MenuItem>

        <MenuItem onClick={() => toggleShowStopsOfType(StopType.DOWNTIME)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              name="DOWNTIME"
              checked={showStopsOfTypes.includes(StopType.DOWNTIME)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['stop:lossDuringOperation'], { defaultValue: 'Loss During Operation' })} />
        </MenuItem>

        <MenuItem onClick={() => toggleShowStopsOfType(StopType.BATCH_NON_PROD)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              name="BATCH_NON_PROD"
              checked={showStopsOfTypes.includes(StopType.BATCH_NON_PROD)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            primary={t(['stop:batchSpecificNonOperation'], { defaultValue: 'Batch Specific Non Operation' })}
          />
        </MenuItem>

        <MenuItem onClick={() => toggleShowStopsOfType(StopType.NON_PROD)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              name="NON_PROD"
              checked={showStopsOfTypes.includes(StopType.NON_PROD)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['stop:nonProductionActivities'], { defaultValue: 'Non Production Activities' })} />
        </MenuItem>

        <MenuItem onClick={() => toggleShowStopsOfType(StopType.UNREGISTERED)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              name="UNREGISTERED"
              checked={showStopsOfTypes.includes(StopType.UNREGISTERED)}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary={t(['stop:unregisteredStops'], { defaultValue: 'Unregistered Stops' })} />
        </MenuItem>

        <Divider />

        <MenuItem dense disabled className={classes.opacity6}>
          <ListItemText
            classes={{ secondary: classes.menuItemTitle }}
            secondary={t(['shared:targetSettings'], { defaultValue: 'Target settings' })}
          />
        </MenuItem>
        <MenuItem onClick={() => setIncludePlannedBatchTimes((s) => !s)} className={classes.menuItemStyles}>
          <ListItemIcon>
            <Checkbox
              color="primary"
              name="includePlannedBatchTimes"
              checked={includePlannedBatchTimes}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText
            primary={t(['shared:includePlannedBatchTimes'], { defaultValue: 'Include planned batch times' })}
          />
        </MenuItem>
        <Divider />
        {dashboardItemType === DashboardItemType.SHIFT && (
          <MenuItem
            onClick={() => {
              setShowGroupSidebar(true);
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <ArrowForward />
            </ListItemIcon>
            <ListItemText primary={t(['shared:viewGroup'], { defaultValue: 'View group...' })} />
          </MenuItem>
        )}
      </Menu>
      {showGroupSidebar && <GroupsSidebarWrapper onSelectGroup={onSelectGroup} />}
    </div>
  );
};

export default DashboardMenu;
